@import '../../../variables.css';

.container {
    height: 100%;
    overflow: auto;
}

.headerTitle {
    font-size: var(--ark-24-font-size);
    color: rgba(255, 255, 255, 0.75);
    line-height: 1.3;
    max-width: 670px;
    text-align: center;
}

.headerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.collectionsWrapper {
    margin: 2rem 0;
}

.collectionList:not(:last-of-type) {
    margin-bottom: 3rem;
}

.loaderWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}

.loaderWrapper p {
    color: rgba(255, 255, 255, 0.75);
    font-size: var(--ark-18-font-size);
    text-align: center;
    margin-top: 1rem;
}

.errorWrapper {
    font-size: var(--ark-28-font-size);
    line-height: 1.3;
    text-align: center;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .container {
        height: calc(100% - 60px - 2rem); /* 60px - height of mobile nav, 2rem - margin for collection items */
    }
}

@media (max-width: var(--ark-medium-mobile-figma-768)) {
    .headerTitle {
        font-size: var(--ark-20-font-size);
        padding: 0 1.5rem;
    }
    .loaderWrapper p {
        font-size: var(--ark-16-font-size);
    }
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .headerTitle {
        font-size: var(--ark-18-font-size);
        padding: 0 1rem;
    }
}
