/* GameCanvas */
.canvasBox,
.canvasBoxWrapper{
    width: 100%;
    height: 100%;
}

/* .canvasBox.fullScreen {
    position: fixed;
    left: 0;
    top: 60px;
    max-height: calc(100% - 60px);
    z-index: 1000000000;
} */

/* GameContainer */
.gameContainer {
    width: 100%;
    height: 100%;
    position: relative;
}

.gameContainerWrapper {
    display: flex;
    flex-direction: row;
}

/* GameUnit */
.unitContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.hidden {
    display: none;
}

.canvasContainer {
    overflow: hidden;
    max-height: 100vh;
    width: 100%;
    height: 100%;
    position: relative;
}

@media (orientation: landscape) {
    .canvasContainer {
        padding-bottom: unset;
    }
}

.testingGoToGameEndButton {
    display: none!important;
}

.canvasBox canvas {
    touch-action: initial!important;
}

[class*="__GRM"] + [id="preroll-container"] {
    position: absolute;
    top: 0;
    overflow: hidden;
}
