@import '../../../variables.css';

.gameEnd {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    overflow: auto;
}

.gameEndContent {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

.animPlayerContainer{
    height: 472px;
    width: 675px;
}

.userName {
    display: inline-block;
    max-width: calc(100% - 11rem);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.caption {
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-32-line-height);
    font-weight: var(--ark-font-weight-bold);
    color: #0c103d;
    text-align: center;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.subCaption {
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-24-line-height);
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-orange-figma);
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0.75rem;
    letter-spacing: 1.5px;
    z-index: 2;
}

.scoresContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
}

.scores {
    height: 5.8rem;
    width: 28.8rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-image: url(./images/Game_end_score_board.svg);
    z-index: 2;
}

.scoresValue {
    font-size: var(--ark-48-font-size);
    line-height: var(--ark-56-line-height);
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-primary-white-figma);
}

.shining {
    background-image: url(./images/Game_end_shine_new.svg);
    width: 100%;
    position: absolute;
    top: 0;
    height: 47%;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: bottom -130px center;
}

.congrats {
    font-size: var(--ark-36-font-size);
    line-height: var(--ark-48-line-height);
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-primary-white-figma);
    padding-top: 0.75rem;
    height: 5.125rem;
    width: 40rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-image: url(./images/Game_end_congrats.svg);
}

.congratulations {
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-26-line-height);
    font-weight: var(--ark-font-weight-regular);
    color: var(--ark-neutral-black);
    width: 25rem;
    text-align: center;
    margin-top: var(--ark-spacing-xxs);
    margin-bottom: var(--ark-spacing-s);
}

.chestAndRibbon {
    height: 200px;
    position: relative;
    width: 300px;
}

.chestAndRibbonSVG {
    position: absolute;
    top: -38px;
    display: block;
    width: 300px;
    height: 224px;
    transform: scale(1.5);
}

.cheerUp {
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-30-line-height);
    font-weight: var(--ark-font-weight-regular);
    color: var(--ark-primary-white-figma);
    padding-top: 2.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.25rem;
}



.button {
    height: 3rem;
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-28-line-height);
    padding: 10px 30px;
    /* Base paddings for all buttons*/
}

.playAgainBtn {
    max-width: 190px;
}

.secondaryBtn {
    margin-right: 2rem;
}

.loginSaveButton {
    margin-right: 2rem;

}


.captionGreetings {
    display: inline-block;
    overflow: hidden;
}



.noTransform {
    transform: none !important;
}

.adContainer {
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    padding-top: 2rem;
    position: absolute;
    bottom: 0;
}

/* specialStylesForUnsubscribed */
.buttons.buttonsSpecial {
    width: 385px;
    flex-direction: row-reverse;
    justify-content: space-between;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.buttons.buttonsSpecial button {
    margin: 0
}

.favoriteTogglerButton {
    background: none;
    border: none;
    box-shadow: none;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 1.25rem;
    color: #FFFFFF;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
}

/* 1440 */
@media (min-width: var(--ark-large-desktop-figma-1440)) {
.animPlayerContainer{
    height: 440px;
    width: 630px;
}
}

/* Low-height desktop */
@media (min-width: 1024px) and (max-height: 664px) {
    .GameEndContent {
        position: relative;
    }
    .adContainer {
        position: relative;
    }
}

/* 1024 */
@media (width: var(--ark-small-desktop-figma-1024)) {

    .freeVersionGameEnd {
        /* background: var(--ark-neutral-dark) !important; */
    }

    .freeVersionGameEnd .gameEndContent {
        background: none;
        box-shadow: none;
    }

    .freeVersionGameEnd .congratulations {
        color: var(--ark-neutral-white);
    }

    .freeVersionGameEnd .congratulations {
        margin-top: var(--ark-spacing-xxl);
        margin-bottom: var(--ark-spacing-l);
    }

    .animPlayerContainer{
        height: 464px;
        width: 666px;  /*¯\_(ツ)_/¯*/
    }
    
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .gameEnd {
        background-position-y: -225px;
    }

    .caption {
        display: block;
        width: auto;
    }

    .adContainer {
        display: none;
    }

    .shining {
        height: 45%;
    }

    .caption {
        font-size: var(--ark-24-font-size);
        line-height: var(--ark-32-line-height);
        font-weight: var(--ark-font-weight-bold);
        margin-bottom: var(--ark-spacing-xl);
    }

    .userName {
        max-width: calc(100% - 2rem);
    }

    .captionGreetings {
        font-size: var(--ark-30-font-size);
        line-height: var(--ark-32-line-height);
        font-weight: var(--ark-font-weight-bold);
        display: block;
        text-align: center;
    }


    .congratulations {
        margin-top: var(--ark-spacing-l);
        font-size: var(--ark-24-font-size);
        line-height: var(--ark-32-line-height);
        width: 100%;
    }


    .loginTextButton {
        font-size: var(--ark-24-font-size);
        line-height: var(--ark-32-line-height);
        font-weight: var(--ark-font-weight-regular);
        margin-top: var(--ark-spacing-xxl-2);
    }

    .animPlayerContainer{
        height: 456px;
        width: 650px;
    }

}

/* 767 */
@media (max-width: var(--ark-large-mobile-below-767)) {
    .gameEnd {
        background-position-y: -240px;
    }

    .cheerUp{
        max-width: 331px;
    }

    .scoresValue {
        font-size: var(--ark-36-font-size);
        line-height: var(--ark-48-line-height);
        font-weight: var(--ark-font-weight-bold);
        color: var(--ark-primary-white-figma);
    }

    .captionGreetings {
        font-size: var(--ark-24-font-size);
        line-height: var(--ark-32-line-height);
        font-weight: var(--ark-font-weight-bold);
    }

    .caption {
        font-size: var(--ark-18-font-size);
        line-height: var(--ark-24-line-height);
        margin-bottom: 0.75rem;
    }

    .shining {
        height: 43%;
    }


    .congratulations {
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-26-line-height);
        font-weight: var(--ark-font-weight-regular);
        color: var(--ark-neutral-black);
        width: 25rem;
        text-align: center;
        margin-top: var(--ark-spacing-l);
        margin-bottom: var(--ark-spacing-s);
    }


    .loginSaveButton {
        margin: 0;
        width: 100%;
        white-space: nowrap;
    }

    .secondaryBtn {
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-28-line-height);
        width: 100%;
        margin: 0;
    }

    .buttons {
        flex-direction: column;
        gap: 1.25rem;
        width: 238px;
        min-height: 110px;
    }

    .playAgainBtn {
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-28-line-height);
        max-width: 100%;
        width: 100%;
        white-space: nowrap;
    }

    /* specialStylesForUnsubscribed */
    .buttons.buttonsSpecial {
        width: 238px;
        flex-direction: column;
    }

    .buttons.userLoginStyle {
        flex-direction: column-reverse;
    }

    .buttons.buttonsSpecial button {
        margin: 0
    }

    .animPlayerContainer{
        height: 355px;
        width: 529px;
    }
}

/* 480 */
@media (max-width: var(--ark-med-small-mobile-480)) {
    .gameEnd {
        overflow-y: auto;
    }

    .gameEndContent {
        height: 100%;
        background-size: 125%;
        padding: 10px 0;

    }

    .scores {
        height: 62px;
        width: 226px;
        background-size: 125%;

    }

    .scoresValue {
        font-size: var(--ark-28-font-size);
        line-height: var(--ark-34-line-height);
        font-weight: var(--ark-font-weight-bold);
        color: var(--ark-primary-white-figma);
    }

    .cheerUp {
        font-size: var(--ark-18-font-size);
        line-height: var(--ark-24-line-height);
        margin-top: 1rem;
        padding-top: 0;
        max-width: 220px;
        height: 30px; 
    }

    .secondaryBtn {
        width: 100%;
    }

    .chestAndRibbonSVG {
        transform: scale(1.2);
    }

    .shining {
        height: calc(50% - 4rem);
    }

    .buttons {
        margin-top: 1.5rem;
    }

    .playAgainBtn {
        width: 231px;
    }

    .loginSaveButton{
        margin-top: 1.25rem;
        margin-right: 0;
    }

    .animPlayerContainer{
        height: 375px;
        width: 537px;
    }
}

/* 320 */
@media (max-width: var(--ark-extra-small-figma)) {
    .gameEndContent{
        justify-content: start;
    }
    .cheerUp{
        font-size: 1rem;
    }

    .animPlayerContainer{
        height: 245px;
        width: 355px;
    }
}
