@import "../../../variables.css";

@keyframes LoadingAnimation {
    50% {
        transform: scale(0.75, 0.75) translateZ(0);
        color: rgba(0, 0, 0, 0.05);
    }
}

.loaderContainer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    background: #f1f1f1;
    color: rgba(0, 0, 0, 0.15);
}

.loaderContainer.fullscreen {
    position: fixed;
}

.loaderText {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 5rem;
    text-align: center;
}

.loadingLetter {
    display: inline-block;
    animation: LoadingAnimation 4s infinite;
    will-change: transform;
}

.loadingLetter:nth-of-type(1),
.loadingLetter:nth-of-type(10) {
    animation-delay: 0.3s;
}

.loadingLetter:nth-of-type(2),
.loadingLetter:nth-of-type(9) {
    animation-delay: 0.225s;
}

.loadingLetter:nth-of-type(3),
.loadingLetter:nth-of-type(8) {
    animation-delay: 0.15s;
}

.loadingLetter:nth-of-type(4),
.loadingLetter:nth-of-type(7) {
    animation-delay: 0.075s;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .loaderText {
        font-size: 3.5rem;
    }
}
