@import '../../../variables.css';

.container {
    margin: 0 auto;
    max-width: 670px;
}

.container.tab {
    max-width: unset;
}

.collectionNameWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
}

.container.tab .collectionNameWrapper {
    justify-content: space-between;
    color: var(--ark-primary-blue-figma);
    font-weight: 700;
}

.collectionNameWrapper.fullCollection {
    color: #f8d15c;
}

.titleWrapper {
    display: flex;
    align-items: center;
}

.titleWrapper.undiscovered {
    color: var(--ark-primary-asbestos-figma);
}

.counterWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 1.25rem;
}

.counterWrapper span {
    font-size: var(--ark-18-font-size);
    margin-top: 0.25rem;
    font-weight: bold;
}

.counterWrapper svg {
    max-width: 24px;
}

.container.tab .counterWrapper svg path {
    stroke: currentColor;
}

.collectionName {
    font-size: var(--ark-28-font-size);
    line-height: 1.3;
    text-align: center;
}

.listWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(var(--card-size-desktop), 100%), 1fr));
    justify-content: space-evenly;
    grid-gap: var(--ark-spacing-xxl);
}

.container.tab .listWrapper {
    justify-content: left;
}

@media (max-width: var(--ark-large-desktop-figma-1440)) {
    .collectionName {
        font-size: var(--ark-24-font-size);
    }

    .container.tab .listWrapper {
        justify-content: left;
    }
}

@media (max-width: var(--ark-medium-mobile-figma-768)) {
    .listWrapper {
        grid-template-columns: repeat(3, auto);
    }
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .listWrapper {
        grid-gap: var(--ark-spacing-s);
    }

    .container.tab .collectionNameWrapper {
        padding: 0 16px;
        margin-bottom: 16px;
    }

    .container.tab .counterWrapper {
        padding-right: 20px;
    }

    .collectionName {
        font-size: var(--ark-18-font-size);
    }

    .container.tab .listWrapper {
        justify-content: center;
    }
}
