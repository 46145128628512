@import '../../../../variables.css';

:root {
    --right-rail-width-small: 376px;
    --right-rail-width-medium: 376px;
    --right-rail-height-bp: 690px;
    --top-320ad-container-height: 107px;
    --top-728ad-container-height: 97px;
    --left-sidebar-width: 100px;
    --bottom-ad-container-height: 147px;
}

.rightAd320 {
    min-width: 300px;
    min-height: 250px;
    z-index: 30;
}

.bottomAd320 {
    min-width: 320px;
    min-height: 180px;
    z-index: 30;
}

.gameAreaWrapper {
    position: relative;
    overflow: hidden;
}

.gameAreaRightAdContainer {
    grid-column: 3 / 4;
    grid-row: 1 /2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.gameAreaTopAdContainer {
    min-height: 132px;
    z-index: 30;
}

.gameAreaContent {
    max-width: 1840px;
    margin: 0 auto;
    z-index: 21;
    position: relative;
}

.gameAreaContent.adFree {
    max-width: 1800px;
}

.gameAreaTopAdContainer,
.gameAreaTopAdContainerMobileLandscape,
.gameAreaTopAdContainerMobilePortrait,
.gameAreaBottomAdContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    overflow: hidden;
}

.gameAreaBottomAdContainer {
    padding-top: 1rem;
}

:global(.sticky-outer-wrapper.active [class*='gameAreaBottomAdContainer']) {
    border-bottom-right-radius: var(--ark-spacing-s);
    padding-top: 0;
}

.gameAreaContainer {
    display: flex;
}

.initialGameAreaContainer {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    visibility: hidden;
}

.gameAreaGameWrapper {
    color: white;
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    height: 100%;
    background-color: var(--ark-preroll-background-color);
}

.fullWidthGameContainer .gameAreaGameWrapper {
    height: calc(100vh - 147px);
    padding: 0;
}

.gameAreaGame {
    width: 100%;
    height: 100%;
}

.gameAreaGame:focus,
.gameAreaGame:focus-visible {
    outline: none;
}

.gameAreaGameContainer {
    width: 1182px;
    height: 900px;
    margin-right: 36px;
}

.fullWidthGameContainer {
    padding: var(--ark-spacing-xxl-3) var(--ark-spacing-xxl-2) var(--ark-spacing-xxl-2);
}

.fullWidthGameContainer .gameAreaGameContainer {
    grid-column: 2 / 4;
}

.leftSideBar {
    padding-top: 8rem;
}

.gameAreaTopAdContainer.mobileHidden {
    min-height: 132px;
}

.mobileVisible,
.topAdMobilePortraitWrapper {
    display: none;
}

/* DESKTOP QUERIES*/

@media (min-width: var(--ark-small-desktop-1025)) {
    .fullScreen {
        position: fixed;
        top: 0;
        left: 0;
        padding: 0;
        width: 100%;
        height: 100vh;
    }

    .noPadding {
        padding: 0 !important;
    }

    .fullWidthGameContainer .gameAreaGameWrapper.fullHeight {
        height: 100vh;
    }

    .gameAreaContainer.gameAreaContainerFullScreen {
        grid-template-columns: 6.25rem minmax(0, 1fr) 0;
    }
}

/* 1900 - infinity */
@media (min-width: var(--ark-large-desktop-1920)) {
    .gameAreaContainer.fullScreen {
        padding: 0;
    }

    .gameAreaGameContainer {
        margin: 0;
        width: 1400px;
    }

    .gameAreaGameContainer.adFree {
        width: 100%;
        height: 100%;
        max-height: initial;
    }

    .gameAreaRightAdContainer {
        margin-left: auto;
        grid-column: unset;
        grid-row: unset;
        row-gap: 40px;
    }
}

@media (orientation: portrait) {
    .topAdMobilePortraitWrapper {
        height: 107px;
    }
}

@media screen and (min-height: 677px) {
    /* Minimum required 667px = 117px ads block + 560px game container */
    .gameAreaGameWrapper {
        min-height: 560px;
        /* we still need it for bigger screen sizes to avoid game shrinking */
        transform: translateZ(0);
        /* to make css be re-applied on resize and re-render correctly */
    }
}
