@import '../../../variables.css';

.container {
    --discovered-rareness-overlay-color: rgba(0, 0, 0, 0.1);
    --discovered-rare-gradient: linear-gradient(143.28deg, #33daec 6.65%, #00a4ba 102.2%);
    --discovered-very-rare-gradient: linear-gradient(143.28deg, #3393ec 6.65%, #0c5fab 102.2%);
    --discovered-ultra-rare-gradient: linear-gradient(143.28deg, #a16aff 6.65%, #48169c 102.2%);
    --discovered-light-card-name-color: #1a1a1a;
    --discovered-rare-card-border-color: #33daec;
    --discovered-very-rare-card-border-color: #3393ec;
    --discovered-ultra-rare-card-border-color: #a16aff;

    --undiscovered-rare-color: #0096aa;
    --undiscovered-very-rare-color: #2079cc;
    --undiscovered-ultra-rare-color: #7b46d5;
    --undiscovered-light-description-color: #6c6c6c;

    --card-border-radius: 20px;
    --card-image-border-width: 3px;
    --card-counter-width-desktop: 45px;
    --card-counter-width-tablet: 32.5px;
}

.container .cardWrapper {
    width: var(--card-size-desktop);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    margin: 0 auto;
}

.container .card {
    width: inherit;
    height: var(--card-size-desktop);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    border-width: 3px;
    border-style: solid;
    border-radius: var(--card-border-radius);
}

.container .cardWrapper.discovered.lightBackground .card {
    box-shadow: 0 4px 10px rgba(18, 103, 115, 0.2), inset 0 0 10px rgba(255, 255, 255, 0.3);
}

.container .cardWrapper.discovered.darkBackground .card {
    box-shadow: 0 0 30px #2ca0af, inset 0 0 10px rgba(255, 255, 255, 0.3);
}

.container .cardWrapper.discoveredRare .card {
    background: var(--discovered-rare-gradient);
    border-color: var(--discovered-rare-card-border-color);
}

.container .cardWrapper.discoveredVeryRare .card {
    background: var(--discovered-very-rare-gradient);
    border-color: var(--discovered-very-rare-card-border-color);
}

.container .cardWrapper.discoveredUltraRare .card {
    background: var(--discovered-ultra-rare-gradient);
    border-color: var(--discovered-ultra-rare-card-border-color);
}

.container .cardWrapper.undiscoveredRare .card {
    border-color: var(--undiscovered-rare-color);
}

.container .cardWrapper.undiscoveredVeryRare .card {
    border-color: var(--undiscovered-very-rare-color);
}

.container .cardWrapper.undiscoveredUltraRare .card {
    border-color: var(--undiscovered-ultra-rare-color);
}

.container .rarenessBlock {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: var(--ark-14-font-size);
    font-weight: var(--ark-font-weight-bold);
    line-height: var(--ark-20-line-height);
}

.container .cardWrapper.discovered .rarenessBlock {
    background-blend-mode: overlay;
    color: var(--ark-neutral-white);
}

.container .cardWrapper.discoveredRare .rarenessBlock {
    background: var(--discovered-rareness-overlay-color) var(--discovered-rare-gradient);
}

.container .cardWrapper.discoveredVeryRare .rarenessBlock {
    background: var(--discovered-rareness-overlay-color) var(--discovered-very-rare-gradient);
}

.container .cardWrapper.discoveredUltraRare .rarenessBlock {
    background: var(--discovered-rareness-overlay-color) var(--discovered-ultra-rare-gradient);
}

.container .cardWrapper.undiscoveredRare .rarenessBlock {
    color: var(--undiscovered-rare-color);
}

.container .cardWrapper.undiscoveredVeryRare .rarenessBlock {
    color: var(--undiscovered-very-rare-color);
}

.container .cardWrapper.undiscoveredUltraRare .rarenessBlock {
    color: var(--undiscovered-ultra-rare-color);
}

.container .imageBlock {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container .cardWrapper.undiscoveredRare img {
    filter: contrast(0) invert(64%) sepia(87%) saturate(6030%) hue-rotate(161deg) brightness(93%);
}

.container .cardWrapper.undiscoveredVeryRare img {
    filter: contrast(0) invert(38%) sepia(82%) saturate(803%) hue-rotate(180deg) brightness(92%);
}

.container .cardWrapper.undiscoveredUltraRare img {
    filter: contrast(0) invert(36%) sepia(15%) saturate(6767%) hue-rotate(238deg) brightness(85%);
}

.container .cardName {
    padding-top: var(--ark-spacing-s);
    text-align: center;
    font-weight: var(--ark-font-weight-regular-bolder);
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-34-line-height);
}

.container .cardWrapper.discovered.lightBackground .cardName {
    color: var(--discovered-light-card-name-color);
}

.container .cardWrapper.discovered.darkBackground .cardName {
    color: var(--ark-neutral-white);
}

.container .cardWrapper.undiscovered.lightBackground .cardName {
    color: var(--undiscovered-light-description-color);
}

.container .cardWrapper.undiscovered.darkBackground .cardName {
    color: var(--ark-neutral-white);
    opacity: 0.6;
}

.container .collectibleCounted,
.container .collectibleNew .card {
    position: relative;
}

.container .itemCounter,
.container .tagNewBlock {
    display: block;
    position: absolute;
}

.container .itemCounter {
    top: calc(0px - var(--card-image-border-width) / 2);
    right: calc((100% - var(--card-size-desktop) - var(--card-image-border-width)) / 2);
    transform: translate(35%, -40%);
    width: var(--card-counter-width-desktop);
    height: var(--card-counter-width-desktop);
    border: 1px solid #f9f9f9;
    padding-bottom: 2.5px;
    overflow: hidden;
    vertical-align: middle;
    border-radius: 50%;
    background-color: #37117a;
    color: #ffffff;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.75;
    text-align: center;
    white-space: nowrap;
}

.container .tagNewBlock {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 41.9%;
    height: 41.9%;
    overflow: hidden;
    border-radius: 0 0 calc(var(--card-border-radius) - 3px) 0;
}

.container .tagNewLabel {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 150%;
    height: 100%;
    transform: translate(40%, 33%) rotate(-45deg);
    background: linear-gradient(135deg, #ff719b 22%, #cf3692 47%);
    box-shadow: 0 0 2.87425px rgba(139, 33, 122, 0.25);
}

.container .tagNewText {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -100%);
    width: 100%;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 100%;
    letter-spacing: 0.4px;
    text-transform: uppercase;
}

@media (max-width: var(--ark-medium-mobile-figma-768)) {
    .container .cardWrapper {
        width: var(--card-size-tablet);
    }

    .container .card {
        height: var(--card-size-tablet);
    }

    .container .card.discovered {
        box-shadow: 0 0 30px #2ca0af, inset 0 0 10px rgba(255, 255, 255, 0.3);
    }

    .container .rarenessBlock {
        align-items: flex-end;
    }

    .container .cardName {
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-28-line-height);
    }

    .container .itemCounter {
        right: calc((100% - var(--card-size-tablet) - var(--card-image-border-width)) / 2);
        width: var(--card-counter-width-tablet);
        height: var(--card-counter-width-tablet);
        font-weight: 800;
        font-size: 1rem;
    }
}

@media (max-width: var(--ark-small-mobile-figma-600)) {
    .container .cardWrapper {
        width: var(--card-size-mobile);
    }

    .container .card {
        height: var(--card-size-mobile);
        border-radius: 10px;
        border-width: 2px;
    }

    .container .rarenessBlock {
        height: auto;
        font-size: var(--ark-12-font-size);
    }

    .container .imageBlock {
        width: 75%;
    }

    .container .cardName {
        padding-top: var(--ark-spacing-xxs);
        font-size: var(--ark-12-font-size);
        line-height: var(--ark-18-line-height);
    }

    .container .itemCounter {
        right: calc((100% - var(--card-size-mobile) - var(--card-image-border-width)) / 2);
    }
}
