@import "../../../variables.css";

.wrapper {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 22.5rem;
    height: 8.25rem;
    background: var(--ark-primary-white-figma);
    box-shadow: 0px 3px 16px rgba(3, 15, 36, 0.35);
    border-radius: 0.625rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}


.info {
    max-width: 13.5rem;
    padding: 9px 11px 9px 16px;
}

.info__avatars {
    height: 3.5rem;
    display: flex;
}

.info__avatars div {
    width: 3.5rem;
}

.info__avatars [data-testid="avatars-list-item"]:nth-child(1) {
    left: 0;
    z-index: 4;

}

.info__avatars [data-testid="avatars-list-item"]:nth-child(2) {
    left: -28px;
    z-index: 3;

}

.info__avatars [data-testid="avatars-list-item"]:nth-child(3) {
    left: -56px;
    z-index: 2;

}

.info__avatars [data-testid="avatars-list-item"]:nth-child(4) {
    left: -84px;
    z-index: 1;
}

.info__text {
    font-size: var(--ark-16-font-size);
    font-weight: var(--ark-font-weight-regular);
    line-height: var(--ark-24-line-height);
    color: var(--ark-primary-royal-blue-figma);
    margin-top: 0.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}


.counterBtn {
    flex-basis: 8rem;
    background: linear-gradient(143.28deg, #A16AFF, #48169C);
    padding: 0 9px 18px 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    transition: background-color 1s linear;
    z-index: 1;
    position: relative;
}



.counterBtn:hover {
    background: linear-gradient(var(--ark-hover-dark-blue-figma), var(--ark-hover-dark-blue-figma));
    box-shadow: 0px 3px 14px rgba(3, 15, 36, 0.15);
}

.counterBtn.counterBtnLoading{
    background: linear-gradient(var(--ark-hover-dark-blue-figma), var(--ark-hover-dark-blue-figma));
    box-shadow: 0px 3px 14px rgba(3, 15, 36, 0.15);
}


.counterBtn.counterBtnReceived:hover,
.counterBtn.counterBtnReceived,
.counterBtn.counterBtnReceived:hover {
    background: linear-gradient(var(--ark-main-green-figma), var(--ark-main-green-figma));
    box-shadow: none;
}


.counterBtn_image {
    width: 2.75rem;
    height: 2.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.counterBtn:hover .counterBtn_image {
    width: 3.5rem;
    height: 3.125rem;
    animation: wobble 0.8s ease 0s 1 normal none;
}

.counterBtn.counterBtnReceived .counterBtn_image {
    width: 4.5rem;
    height: 4.25rem;
}
.counterBtn:hover.counterBtnReceived .counterBtn_image{
    animation: none;
} 
.counterBtn:hover:disabled .counterBtn_image {
    width: 2.75rem;
    height: 2.5rem;
    animation: none;
}


.counterBtn .counterBtn_image svg {
    width: 100%;
    height: 100%;
}

.counterBtn_text {
    font-size: var(--ark-16-font-size);
    font-weight: var(--ark-font-weight-bold);
    line-height: var(--ark-24-line-height);
    color: var(--ark-primary-white-figma);
    text-align: center;
    z-index: 3;
}

/* DISABLED*/
.counterBtn:disabled,
.counterBtn:hover:disabled {
    background: linear-gradient(var(--ark-hover-dark-blue-figma), var(--ark-hover-dark-blue-figma));
    box-shadow: 0px 3px 14px rgba(3, 15, 36, 0.15);
    color: var(--ark-primary-white-figma);
    position: relative;
}

.counterBtn:disabled::after{
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: var(--ark-primary-white-figma);
    border-radius: 0.625rem;
    opacity: 0.5;
    z-index: 100;

}

.animatedBlock{
    opacity: 0;
    transition: opacity 0.2s;
}



@keyframes wobble {

    0%,
    100% {
        transform: translateX(0%);
        transform-origin: 50% 50%;
    }

    15% {
        transform: translateX(-25px) rotate(-6deg);
    }

    30% {
        transform: translateX(15px) rotate(6deg);
    }

    45% {
        transform: translateX(-15px) rotate(-3.6deg);
    }

    60% {
        transform: translateX(9px) rotate(2.4deg);
    }

    75% {
        transform: translateX(-6px) rotate(-1.2deg);
    }
}

