@import "../../../variables.css";

.leaderboardSkeletonContainer {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: hidden; 
}

.leaderboardContent {
    width: 100%;
}

.leaderboardBody {
    position: relative;
    overflow: hidden;
}

.leaderboardHeader {
    background-color: var(--ark-primary-blue-figma);
    height: 4rem;
}

.leaderboardRow {    
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 4fr;
    grid-template-areas: 
        "avatar .";
    width: 100%;
    height: 4rem;
    background-color: var(--ark-neutral-white);
}

.avatar {
    grid-area: avatar;
    height: 3.25rem;
    width: 3.25rem;    
    background-color: var(--ark-neutral-light);
    border-radius: 50%;
    justify-self: end;
    align-self: center;
}

.avatarStriped {     
    background-color: var(--ark-neutral-medium);
}

.rowStriped {
    background-color: var(--ark-neutral-light);
}

.gamePromoContainer {
    background-color: var(--ark-neutral-white-80);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    width: 100%;    
    display: none;
}

.gamePromo {
    min-width: 12.813rem;    
    max-width: 17.5rem;
    width: 100%;   
}


@keyframes highlight {
    from {
        left: -80px;
    }

    to {
        left: calc(100% + 80px);
    }
}

.shadow {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0;
    background: #fff;
    width: 0px;
    box-shadow: 0 0 35px 35px rgba(255, 255, 255, 0.5);
    z-index: 10;
    animation-duration: 1s;
    animation-name: highlight;
    animation-timing-function: var(--ark-ease-in-out-sine);
    animation-iteration-count: infinite;
}

/* 767px and lower */
@media (max-width: var(--ark-large-mobile-below-767)) {
    .leaderboardHeader {        
        height: 3rem;
    }    
}


/* 1600px and higher */
@media (min-width: 1600px) {
    .leaderboardContent {
       min-width: 827px;
       max-width: 827px;
    }
 
    .gamePromoContainer {       
        display: flex;
    }
}