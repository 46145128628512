@import "../../../variables.css";

.bannerContainerWrapper {
    position: relative;
}

.bannerContainer {
    height: 5.5rem;
    background-color: var(--ark-neutral-dark);
    border-top: 1px solid var(--ark-neutral-white);
    border-bottom: 1px solid var(--ark-neutral-white);
    align-items: center;
    display: grid;
    grid-template-columns: 0 2.125rem 0 4rem 0.5rem 1fr 0 7.5rem 0.5rem;
    position: relative;
    width: 100%;
}

.closeIcon {
    color: var(--ark-neutral-white);
    grid-column: 2/3;
}

.gameIcon {
    width: 4rem;
    height: 4rem;
    grid-column: 4/5;
}

.gameIcon img{
    width: inherit;
    border-radius: var(--ark-border-radius);
}

.textBlock {
    color: var(--ark-neutral-white);
    font-size: var(--ark-12-font-size);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    grid-column: 6/7;
}

.topText {
    font-weight: bold;
    height: 3.063rem;
    align-items: center;
    display: flex;
}

.bottomText {
    font-weight: normal;
}

.appIcon {
    grid-column: 8/9;
}

.hidden {
    display: none;
}

@media (min-width: 576px) {
    .bannerContainer {
        grid-template-columns: 1.25rem 2.125rem 2.5rem 4rem 1.25rem 1fr 0 7.5rem 1.25rem;
    }
}
