@import '../../../variables.css';

.loader {
    height: 160px;
    margin-top: 1rem;
}

.preloaderContent {
    display: block;
    height: 100%;
    background: url(./illustrations/preroll/preroll-bg.webp);
    background-repeat: no-repeat;
    background-position: top right;
    background-color: var(--ark-advantage-blue-figma);
    width: 100%;
}

.preloaderContent.preloaderContentSubsOnly{
    background: url(./illustrations/preroll/subs-only-bg.webp);
    background-repeat: no-repeat;
    background-position: top right;
    background-color: var(--ark-advantage-blue-figma);
}

.preloaderContentWrapper {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
    flex-wrap: wrap;
}

.desktopPreloaderWrapper {
    display: initial;
}

.preloaderWrapper {
    position: absolute;
    background-color: rgba(16, 27, 76, 1);
    height: 100%;
    width: 100%;
    display: flex;
    z-index: 2000;
    top: 0;
}

@media (orientation: landscape) {
    .preloaderWrapper {
        padding-bottom: 0;
    }
}

/**************Subscriber Only*/
.subsOnlyTitle {
    text-align: center;
    font-weight: var(--ark-font-weight-light);
    font-size: var(--ark-36-font-size);
    line-height: var(--ark-48-line-height);
    padding: 0 1rem;
}

.subsOnlySubTitle {
    text-align: center;
    font-weight: var(--ark-font-weight-light);
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-28-line-height);
    margin-top: 12px;
    padding: 0 1rem;
}

.joinBtn {
    margin-top: 36px;
    padding: 10px 25px;
}

.noPrerollOnlyTitle {
    margin-top: -20px;
    text-align: center;
    font-size: var(--ark-56-font-size);
    line-height: 8rem;
    padding: 0 1rem;
}

.noPrerollJoinBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 56px;
    gap: 8px;
    width: 263px;
    height: 72px;
    background: radial-gradient(92.09% 85.42% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(65.28% 65.28% at 26.39% 20.83%, rgba(255, 255, 255, 0.413) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #12B76A;
    box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.4), inset -3px -4px 7px rgba(255, 255, 255, 0.15);
    border-radius: 72px;
    flex: none;
    order: 2;
    align-self: center;
    flex-grow: 0;
}

@media(max-width: var(-ark-small-desktop-1025)) {
    .desktopPreloaderWrapper {
        display: none;
    }

    .preloaderWrapper {
        display: flex;
    }
}

@media screen and (max-width: var(--ark-small-mobile-figma-below-599)),
    screen and (max-height: 460px) {
    .noPrerollOnlyTitle {
        margin-top: -20px;
        text-align: center;
        font-size: var(--ark-30-font-size);
        line-height: var(--ark-80-line-height);
        padding: 0 1rem;
    }

    .subsOnlyTitle {
        margin-top: -20px;
        text-align: center;
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-26-line-height);
        padding: 0 1rem;
    }

    .joinBtn {
        margin-top: 6px;
        font-size: var(--ark-14-font-size);
        height: auto;
        min-height: auto;
        line-height: var(--ark-18-font-size);
        padding: 10px 20px;
    }

    .subsOnlySubTitle {
        margin-top: 10px;
        font-weight: var(--ark-font-weight-light);
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-24-line-height);
    }
}

@media (max-width: var(--ark-extra-small-figma)) {
    .preloaderContent {
        background: none;
    }
}

@media screen and (max-width: var(--ark-small-desktop-1025)) {
    .preloaderContent.preloaderContentSubsOnly {
        background-size: auto 54%;
    }
}
