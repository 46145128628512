@import "../../../../variables.css";

.bottomAd {
    padding-bottom: 1rem;
}

:global(.sticky-outer-wrapper.active [class*="bottomAd"]) {
    padding-bottom: var(--ark-spacing1);
}

.bottomAdsWrapper {
    display: block;
    border-bottom-right-radius: var(--ark-spacing-s);
}

.bottomAdsWrapper::before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 100vw;
    left: 0;
    transform: translateX(-100%);
    background-color: inherit;
}

.mobileTopAd {
    padding-bottom: 0.438rem;
}

/* 1900 - infinity */
@media (min-width: var(--ark-large-desktop-1920)) {
    .gameAreaBottomContainer {
        padding: 20px 0;
        display: grid;
        grid-template-columns: 1500px auto;
    }

    .stickyContainer {
        display: grid;
        grid-template-columns: 100px 1400px;
    }

    .stickyContainer:before {
        content: '';
        display: block;
        height: 100%;
    }
}
