@import '../../../variables.css';

.container {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--ark-preroll-background-color);
}

.container > div {
    position: relative;
    height: 100%;
    top: -1rem;
}

.container.fullScreen {
    width: 100% !important;
    height: 100% !important;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    background-color: var(--ark-preroll-background-color);
}

:global(ark-ad-div.ark-ad-inner.ctaContainer) {
    background-image: none;
}

:global(.ctaWrapper) {
    display: grid;
    gap: 36px;
    grid-template-areas:
        'header header'
        'leftButton rightButton'
        'textBlock textBlock'
        'footer footer';
    padding-bottom: 57px;
}

:global(.ctaRemoveAdsText) {
    grid-area: textBlock;
    text-decoration-line: underline;
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-28-line-height);
    color: var(--ark-yellow-1);
    display: flex;
    justify-content: center;
    text-align: center;
}

:global(.ctaHeader) {
    grid-area: header;
}

:global(.ctaRightButton) {
    grid-area: rightButton;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 24px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: rgba(249, 249, 249, 1);
    font-size: var(--ark-20-font-size);
    height: 48px;
    font-weight: var(--ark-font-weight-bold);
    text-transform: none;
    border: 2px solid rgba(249, 249, 249, 1);
    width: 223px;
    justify-self: flex-start;
}

:global(.ctaRightButton):hover {
    background-color: var(--ark-primary-white-figma);
    border-color: transparent;
    color: var(--ark-preroll-background-color);
}

:global(.ctaRightButton):focus-visible {
    outline-offset: 4px;
    outline: dashed var(--ark-focus-figma) var(--focus-state-outline-width);
}

:global(.ctaRightButton) {
    display: flex;
    flex-direction: row;
}

:global(.ctaRightButton img) {
    margin: 0 3px 0 15px;
    width: 32px;
    height: 24px;
}

:global(.ctaButtonText) {
    grid-area: leftButton;
}

:global(.ctaFooter) {
    grid-area: footer;
    position: absolute;
    bottom: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 520px;
}

:global(.ctaLogoSlogan) {
    color: var(--ark-primary-white-figma);
    font-size: var(--ark-15-font-size);
    line-height: var(--ark-32-line-height);
}

:global(.ctaLogo) {
    width: 140px;
    height: 41px;
}

:global(.ctaSlogan) {
    font-weight: var(--ark-font-weight-light);
    font-size: var(--ark-36-font-size);
    line-height: var(--ark-48-line-height);
    color: var(--ark-primary-white-figma);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-family: var(--ark-font-family);
}

:global(.ctaMessageBox),
:global(.ctaColLef) {
    display: none;
}

:global(.ctaColRight) {
    display: block;
    width: 180px;
    justify-self: flex-end;
    grid-area: leftButton;
}

:global(.ctaColLeft),
:global(ark-ad-div.ark-ad-button-arrow) {
    display: none !important;
}

:global(.ctaButton) {
    background: none !important;
    margin: 0 !important;
    padding: 0;
    border-radius: 24px;
    outline-offset: 4px;
}

:global(.ctaButton):focus-visible {
    outline: dashed var(--ark-focus-figma) var(--focus-state-outline-width);
}

:global(.ctaButtonText) {
    outline: none;
    border: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(1, 124, 85, 1);
    border-radius: 24px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: rgba(249, 249, 249, 1);
    font-size: var(--ark-20-font-size) !important;
    height: 48px;
    font-weight: var(--ark-font-weight-bold) !important;
    text-transform: none;
    margin: 0 !important;
    width: 170px;
}

:global(.ctaButtonText):hover
/*:global(.ctaButtonText):focus*/ {
    background-color: var(--ark-hover-dark-green-figma);
}

:global(.ctaRightButtonLoadingVisibility){
    display: none;
}

:global(.loading .ctaRightButtonLoadingVisibility){
    display: block;
}

:global(.loading .ctaRightButtonVisibility){
    display: none;
}

:global(.spinnerPreroll) {
    margin-left: 10px;
    width: 30px;
    height: 30px;
    animation: rotator 1.4s linear infinite;
    will-change: transform, animation;
}

:global(.pathPreroll) {
    stroke-width: 6px;
    stroke-linecap: round;
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    will-change: transform, animation;
    animation:
            dash 1.4s ease-in-out infinite,
            colors 5.6s ease-in-out infinite;
}


@keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
}

@keyframes colors {
    0% { stroke: #08B238; }
    25% { stroke: #9AEFB0; }
    50% { stroke: #08B238; }
    75% { stroke: #9AEFB0; }
    100% { stroke: #08B238; }
}

@keyframes dash {
    0% { stroke-dashoffset: 187px; }
    50% {
        stroke-dashoffset: 46.75px;
        transform:rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187px;
        transform:rotate(450deg);
    }
}

.player.fullScreen {
    height: 100vh;
}

.player {
    display: block;
    height: 100%;
    background: url(./illustrations/preroll/preroll-bg.webp);
    background-repeat: no-repeat;
    background-position: top right;
    background-color: var(--ark-advantage-blue-figma);
}

.player.fullScreen {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}

@media (max-width: var(--ark-medium-desktop-figma-below-1359)) {
    :global(.ctaSlogan) {
        font-size: var(--ark-24-font-size);
        line-height: var(--ark-30-line-height);
    }

    :global(.ctaFooter) {
        width: 386px;
    }

    :global(.ctaWrapper) {
        gap: 22px;
    }
}

@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    :global(.ctaSlogan) {
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-28-line-height);
        margin-bottom: 6px;
    }

    :global(.ctaWrapper) {
        display: grid;
        gap: 16px;
        grid-template-areas:
            'header header'
            'leftButton leftButton'
            'rightButton rightButton'
            'textBlock textBlock'
            'footer footer';
        padding-bottom: 57px;
        justify-items: center;
    }

    :global(.ctaRightButton) {
        justify-self: center;
    }

    :global(.ctaColRight) {
        width: 100%;
    }

    :global(.ctaFooter) {
        width: 290px;
        bottom: 25px;
    }

    :global(.ctaRemoveAdsText) {
        text-align: center;
        font-size: var(--ark-16-font-size);
    }
}

@media (max-width: var(--ark-extra-small-figma)) {
    .player {
        background: none;
    }
    :global(.ctaFooter) {
        bottom: 0;
    }
}

@media(min-width: var(--ark-small-desktop-figma-1024)) {
    .player > [class*="vast-media"] {
        max-height: calc(100% - 3rem);
        overflow: hidden;
    }
}
