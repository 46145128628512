.rightAd320 {
    min-width:376px;
    min-height: 322px;
    z-index: 30;
}
.topAd720 {
    min-width:728px;
    min-height: 132px;
}

.feedbackRow{
    display: flex;
    align-items: center;
    flex-direction: column;
}