@import '../../../variables.css';

:root {
    --right-rail-width-small: 376px;
    --right-rail-width-medium: 376px;
    --right-rail-height-bp: 690px;
    --top-320ad-container-height: 107px;
    --top-728ad-container-height: 97px;
    --left-sidebar-width: 100px;
    --bottom-ad-container-height: 147px;
}

.gameAreaRightAdContainer {
    width: 376px;
}

.gameAreaRightAdContainerMobile {
    width: 160px;
}

.gameAreaTopAdContainer {
    min-height: 132px;
    z-index: 30;
}

.gameAreaContent {
    max-width: 1800px;
    margin: 0 auto;
    z-index: 21;
    position: relative;
}

.gameAreaTopAdContainer,
.gameAreaTopAdContainerMobileLandscape,
.gameAreaTopAdContainerMobilePortrait,
.gameAreaBottomAdContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    overflow: hidden;
}

.gameAreaBottomAdContainer {
    padding-top: 1rem;
}

:global(.sticky-outer-wrapper.active [class*='gameAreaBottomAdContainer']) {
    border-bottom-right-radius: var(--ark-spacing-s);
    padding-top: 0;
}

.gameAreaContainer {
    display: grid;
    grid-template-columns: 6.25rem minmax(0, 1fr) var(--right-rail-width-medium);
    grid-template-rows: auto;
}

.initialGameAreaContainer {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    visibility: hidden;
}

.gameAreaGameWrapper {
    height: calc(100vh - var(--bottom-ad-container-height));
    color: white;
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    background-color: var(--ark-preroll-background-color);
}

.gameAreaGame {
    width: 100%;
    height: 100%;
}

.gameAreaGame:focus,
.gameAreaGame:focus-visible {
    outline: none;
}

.gameAreaLeftSideButtons {
    grid-column: 1 /2;
    grid-row: 1 /2;
}

.gameAreaGameContainer {
    grid-column: 2 /3;
    grid-row: 1 /2;
}

.areaHeaderBannerMobile {
    position: fixed;
    z-index: 31;
    font-size: var(--ark-16-font-size);
    font-weight: var(--ark-font-weight-regular);
    color: var(--ark-neutral-white);
    background-color: var(--ark-tertiary-light-color);
    padding: 10px 16px;
    width: 100%;
}

.areaHeaderBannerMobile a {
    color: var(--ark-yellow-1);
    text-decoration: underline;
}

.fullWidthGameContainer {
    padding: var(--ark-spacing-xxl-3) var(--ark-spacing-xxl-2) var(--ark-spacing-xxl-2);
    max-height: initial;
}

.fullWidthGameContainer .gameAreaGameContainer {
    grid-column: 2 / 4;
}

.fullWidthGameContainer .gameAreaGameWrapper {
    padding: 0;
}

.gameAreaRightAdContainer,
.gameAreaRightAdContainerMobile {
    padding-top: 3.75rem;
    grid-column: 3 / 4;
    grid-row: 1 /2;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.leftSideBar {
    padding-top: 8rem;
}

.gameAreaTopAdContainer.mobileHidden {
    min-height: 132px;
}

.mobileVisible,
.topAdMobilePortraitWrapper {
    display: none;
}

@media (min-width: var(--ark-large-desktop-1920)) {
    .gameAreaBottomAdContainer {
        padding: 0;
    }
}

/* DESKTOP QUERIES*/
/* 1025 - 1359 */
@media (min-width: var(--ark-small-desktop-1025)) and (max-width: var(--ark-medium-desktop-figma-below-1359)) {
    .gameAreaContainer {
        grid-template-columns: 6.25rem minmax(0, 1fr) auto;
    }

    .gameAreaTopAdContainer,
    .gameAreaBottomAdContainer {
        justify-content: flex-start;
        margin-left: calc(-1 * var(--left-sidebar-width));
    }
}

/* 1025 - 1199px */
@media (min-width: var(--ark-small-desktop-1025)) and (max-width: var(--ark-medium-desktop-below-1199)) {
    .gameAreaContainer {
        padding-left: var(--ark-spacing-s);
        padding-right: var(--ark-spacing-s);
    }
}

/* 1200 - infinity */
@media (min-width: var(--ark-medium-desktop-1200)) {
    .gameAreaContainer {
        padding-left: var(--xxl-new-space);
        padding-right: 0;
    }

    .fullWidthGameContainer.gameAreaContainer {
        padding: var(--ark-spacing-xxl-3) var(--ark-spacing-xxl-2) var(--ark-spacing-xxl-2);
    }

    .gameAreaGameContainer.addPaddingRight {
        padding-right: var(--ark-spacing-m);
    }
}

/* 1360 - 1800px */
@media (min-width: var(--ark-medium-desktop-figma-1360)) and (max-width: var(--ark-large-desktop-1800)) {
    .gameAreaContainer {
        grid-template-columns: 6.25rem minmax(0, 1fr) var(--right-rail-width-small);
    }
}

/* DEVICE GENERAL QUERIES */
/* 0 - 1024px */
@media (max-width: var(--ark-small-desktop-figma-1024)) {

    .gameAreaWrapper {
        height: calc(100vh - var(--ark-mobile-header-height));
    }

    .gameAreaLeftSideButtons {
        display: none;
    }

    .topAdMobilePortraitWrapper {
        display: initial;
    }

    .gameAreaContent {
        background-color: inherit;
        height: 100%;
    }

    .gameAreaContainer {
        background-color: inherit;
        height: 100%;
    }

    .fullWidthGameContainer {
        padding: 0;
        height: 100%;
    }

    .fullWidthGameContainer .gameAreaGameContainer {
        height: 100%;
    }

    .gameAreaContainerFullScreen {
        background-color: inherit;
    }

    .gameAreaContainerFullScreenFullHeight {
        height: 100%;
    }

    .gameAreaContainerFullScreenNotFullHeight {
        height: calc(100% - var(--side-menu-horizontal-size));
    }

    .mobileVisible {
        display: initial;
    }

    .mobileHidden {
        display: none;
    }
}

/* DEVICE LANDSCAPE QUERIES */
/* width: 1024 and less
   height: 1024 and less
   orientation landscape */
@media (max-width: var(--ark-small-desktop-figma-1024)) and (max-height: var(--ark-small-desktop-figma-1024)) and (orientation: landscape) {
    body {
        height: 100%;
    }

    .gameAreaContainer {
        width: calc(100vw - var(--side-menu-size));
        display: flex;
        flex-direction: row;
    }

    .gameAreaContainerFullScreen {
        position: fixed;
        top: 0;
        bottom: 0;
        left: var(--side-menu-size);
        right: 0;
        width: calc(100vw - var(--side-menu-size));
        display: flex;
        flex-direction: row;
    }

    .fullWidthGameContainer {
        width: 100%;
        left: 0;
    }

    .gameAreaGameContainer {
        height: 100%;
        width: 100%;
        padding-right: 0;
    }

    .gameAreaGameWrapper {
        height: 100%;
        width: 100%;
    }

    .gameAreaGameWrapper.hurdleFix {
        height: 100vh;
    }

    .gameAreaRightAdContainerMobile {
        height: 100%;
        width: auto;
        padding-top: 1rem;
    }

    .supportCardMobile {
        padding-top: 0;
    }
}

/* width: 1024 and less
   height: 690 and less
   orientation landscape */
@media (max-width: var(--ark-small-desktop-figma-1024)) and (max-height: var(--right-rail-height-bp)) and (orientation: landscape) {
    .gameAreaContainer {
        flex-direction: column;
    }

    .gameAreaContainerFullScreen {
        flex-direction: column;
    }

    .gameAreaGameContainer {
        height: calc(100% - var(--top-320ad-container-height));
    }
}

@media (orientation: portrait) {
    .topAdMobilePortraitWrapper {
        height: 107px;
    }
}

/* width: 1024 and less
   height: 575 and less
   orientation landscape */
@media (max-width: var(--ark-small-desktop-figma-1024)) and (max-height: var(--ark-small-mobile-below-575)) and (orientation: landscape) {
    .gameAreaGameContainer {
        height: 100%;
    }
}

/* DEVICE PORTRAIT QUERIES */
/* width: 320 - 1024
   orientation portrait */
@media (min-width: var(--ark-extra-small-figma)) and (max-width: var(--ark-small-desktop-figma-1024)) and (orientation: portrait) {
    body {
        height: 100%;
    }

    .gameAreaContainer {
        width: 100vw;
        display: flex;
        flex-direction: column;
    }

    .gameAreaContainerFullScreen {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100vw;
        display: flex;
        flex-direction: column;
    }

    .gameAreaGameContainer {
        height: calc(100% - (var(--top-728ad-container-height)));
        width: 100%;
        padding-right: 0;
    }

    .fullWidthGameContainer .gameAreaGameContainer {
        height: 100%;
    }

    .gameAreaGameWrapper {
        height: 100%;
        width: 100%;
    }

    .gameAreaGameWrapper.hurdleFix {
        height: 100vh;
    }
}

/* width: 320 - 767
   orientation portrait */
@media (min-width: var(--ark-extra-small-figma)) and (max-width: var(--ark-large-mobile-below-767)) and (orientation: portrait) {
    .gameAreaGameContainer {
        height: calc(100% - (var(--top-320ad-container-height)));
    }

    .fullWidthGameContainer .gameAreaGameContainer {
        height: 100%;
    }
}

@media (min-width: var(--ark-small-desktop-1025)) {
    .fullScreen {
        position: fixed;
        top: 0;
        left: 0;
        padding: 0;
        width: 100%;
        height: 100vh;
    }

    .noPadding {
        padding: 0 !important;
    }

    .fullHeight {
        height: 100vh;
    }

    .gameAreaContainer.gameAreaContainerFullScreen {
        grid-template-columns: 6.25rem minmax(0, 1fr) 0;
    }
}

@media screen and (min-height: 677px) {
    /* Minimum required 667px = 117px ads block + 560px game container */
    .gameAreaGameWrapper {
        min-height: 560px;
        /* we still need it for bigger screen sizes to avoid game shrinking */
        transform: translateZ(0);
        /* to make css be re-applied on resize and re-render correctly */
    }
}

@media screen and (max-width: var(--ark-small-desktop-1025)) {
    .gameAreaWrapperSubsOnly + .gameAreaWrapper {
        height: 690px !important;
    }
}

@media screen and (max-height: 450px) {
    .gameAreaWrapperSubsOnly + .gameAreaWrapper {
        height: 360px !important;
    }
}
