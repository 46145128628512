@import '../../../../../variables.css';

.gameAreaBottom {
    grid-column: 2/3;
    grid-row: 1/3;
    padding: var(--xxl-new-space);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.gameAreaBottomPromoContainer {
    padding: var(--xxl-new-space);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.gameAreaFooterBottomAdContainer {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}

.gameLicensingContainer {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    padding-top: var(--ark-spacing-xxs);
    padding-bottom: 4rem;
    justify-content: center;
    display: grid;
}

.adDividerClassName p {
    font-size: var(--ark-22-font-size);
    line-height: var(--ark-32-line-height);
}

.adDividerClassName p a {
    color: var(--ark-tertiary-color);
}

.adDividerClassName img {
    top: 0.7rem;
}

.tagSpacing {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

/* 1025 - 1199px */
@media (min-width: var(--ark-small-desktop-1025)) and (max-width: var(--ark-medium-desktop-below-1199)) {
    .gameAreaBottom {
        grid-column: 1/2;
        grid-row: 2/3;
        padding-bottom: 4rem;
        padding-top: 3rem;
    }

    .gameAreaFooterBottomAdContainer {
        align-self: flex-end;
    }

    .gameLicensingContainer {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 2.375rem;
    }
}