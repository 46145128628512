@import '../../../variables.css';

.tooltipTip {
    position: fixed;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    padding: 1rem;
    color: var(--ark-neutral-white);
    background-color: var(--ark-neutral-dark);
    font-size: var(--ark-14-font-size);
    font-weight: var(--ark-font-weight-bold);
    line-height: var(--ark-18-line-height);
    z-index: 100;
    white-space: normal;
}
.screenReaderOnly {
    transform: scale(0);
}
