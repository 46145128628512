@import '../../../variables.css';

.button {
    position: relative;
    border: none;
    color: var(--ark-neutral-white);
    font-weight: 700;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    height: 5rem;
    border-radius: 0;
}

.button:hover {
    background-color: rgba(0, 255, 255, 0.06);
}

.button .buttonCaption {
    font-size: var(--ark-12-font-size);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    text-transform: none;
    color: var(--ark-neutral-white);
}

.list:not(.disabled) .active {
    background-color: rgba(0, 255, 255, 0.15);
    outline-offset: calc(var(--focus-state-outline-width) * -1);
}

.button.lastButton:hover, .button.lastButton .buttonCaption {
    color: var(--ark-neutral-white);
}

.list {
    display: flex;
    flex-direction: column;
    width: 6.25rem;
    background-color: rgba(0, 0, 0, 0.4);
    height: calc(100vh - 147px);
    min-height: 560px;
    margin-top: 8.25rem;
}

.disabled .button {
    opacity: 0.28;
}

.button .disabled {
    opacity: 0.28;
    color: var(--ark-neutral-white);
}

.disabled .button .buttonCaption:not(.visibleOnDisabled) {
    display: none;
}

.noMargin {
    margin: 0;
}

.newStatus::before {
    background-image: url(./images/new_bg.png);
    background-position: bottom;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    position: absolute;
    width: 100px;
    height: 132px;
    content: ' ';
    left: 1px;
    top: 0;
}

.commentsIcon>path {
    stroke: var(--ark-primary-white-figma);
    stroke-width: 3px;
}

.buttonContent {
    position: absolute;
    top: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.stanNewmanButtonContent {
    top: 14px;
    gap: 0;
}

.stanNewmanButtonContent .buttonCaption {
    font-weight: var(--ark-font-weight-bold);
    width: 50%;
}

.stanNewmanButtonContent>span {
    margin-top: 6px;
}

.buttonContent>span {
    color: var(--ark-primary-royal-blue-figma);
    text-transform: uppercase;
    font-size: var(--ark-14-font-size);
    font-weight: var(--ark-font-weight-extraBold);
}

.fullscreenButtonText {
    text-transform: uppercase;
    font-size: var(--ark-14-font-size);
    font-weight: var(--ark-font-weight-extraBold);
    margin-bottom: 22px;
}

.lastButton {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--ark-neutral-white);
    gap: 8px;
}

button.turnedOff, button.turnedOff .buttonCaption {
    color: var(--ark-secondary-solid-midgrey);
}

.newStatus.button .buttonCaption, .newStatus.button.active .buttonCaption {
    color: var(--ark-primary-royal-blue-figma);
}

.newStatus .buttonCaption {
    margin-top: 5px;
}

.newStatus {
    margin-bottom: 80px;
}

.tooltip {
    position: absolute;
    left: 105px;
    top: 0;
    min-width: 206px;
    border-radius: 10px;
    font-size: var(--ark-16-font-size);
    font-weight: var(--ark-font-weight-bold);
    line-height: var(--ark-20-line-height);
    background-color: var(--ark-advantage-blue-figma);
}

.tooltipGameNavigation {
    position: inherit;
    border-radius: 10px;
    font-size: var(--ark-16-font-size);
    font-weight: var(--ark-font-weight-bold);
    line-height: var(--ark-20-line-height);
    background-color: var(--ark-advantage-blue-figma);
    min-width: 206px;
}

.tooltipComments {
    border: 3px solid rgba(194, 140, 43, 1);
    min-width: 240px;
    left: 0;
    top: -115px;
}

.tooltipCommentsFullScreen {
    left: 105px;
    top: -17px;
}

.tooltipLeaderboard {
    left: 105px;
    top: 0;
}

.tooltipFullScreen {
    position: absolute !important;
    border-radius: 10px;
    font-size: var(--ark-16-font-size);
    font-weight: var(--ark-font-weight-bold);
    line-height: var(--ark-20-line-height);
    background-color: var(--ark-advantage-blue-figma);
    border: 3px solid rgba(194, 140, 43, 1);
    left: 0;
    top: -100px;
    min-width: 206px;
}

@media (min-width: var(--ark-small-desktop-1025)) {
    .fullHeight {
        height: 100vh;
        margin: 0;
    }
}

@media (min-width: var(--ark-large-desktop-1920)) {
    .list:not(.noMargin) {
        height: 900px;
        margin: 0;
    }

    .list:not(.noMargin).fullHeight {
        height: 100vh;
    }
}

/* FAVORITES */
.buttonFavorite {
    position: relative;
}
.buttonFavoriteHint {
    display: none;
    width: 195%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0, -100%);
    padding: 1rem;
    color: #FFFFFF;
    background-color: #000000;
    border: 2px solid #FFFFFF;
    border-radius: .5rem;
    font-family: var(--ark-font-family);
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    z-index: 999;
}
.buttonFavorite:hover .buttonFavoriteHint {
    display: block;
}
[class*="GameTemplate-fullScreen-"] .buttonFavoriteHint {
    left: 100%;
    top: 50%;
    transform: translate(3px, calc(-50% + 3px));
    background-color: var(--ark-advantage-blue-figma);
    border-color: var(--ark-advantage-blue-figma);
}
