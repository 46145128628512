@import '../../../variables.css';

:root {
    --icon-container-size: 100px;
    --icon-container-size-small: 60px;
    --height-bp-1: 812px;
    --height-bp-2: 736px;
    --height-bp-3: 667px;
    --height-bp-4: 568px;

    --height-bp-land-1: 633px;
    --height-bp-land-2: 374px;
}

.container {
    width: 100%;
    height: 100%;
    overflow: auto;
    background-size: contain;
    line-height: 1.9;
}

.adblockerHeader {
    position: relative;
    height: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.adblockerPlusIcon svg {
    width: 164px !important;
    height: auto !important;
}

.adblockerHeaderIconContainer {
    position: relative;
    box-sizing: border-box;
    height: var(--icon-container-size);
    width: var(--icon-container-size);
    background-color: white;
    border-radius: 50%;
    transform: translateY(50%);
    filter: drop-shadow(0px 3px 14px rgba(3, 15, 36, 0.15));
    display: flex;
    align-items: center;
    justify-content: center;
}

.adblockerHeaderIcon {
    width: calc(var(--icon-container-size) / 1.5);
    height: calc(var(--icon-container-size) / 1.5);
}

.adblockerSectionTop {
    background-color: var(--ark-neutral-white);
    text-align: center;
    color: var(--ark-primary-black-figma);
    padding: 0 var(--ark-spacing-xxl-7) var(--ark-spacing-m);
}

.adblockerTextTop {
    font-size: var(--ark-28-font-size);
    font-weight: var(--ark-font-weight-light);
    padding-top: var(--ark-spacing-xxl-5);
}

.adblockerTextMiddle {
    padding: var(--ark-spacing-s) 0;
    line-height: var(--ark-spacing-l);
    font-size: var(--ark-18-font-size);
}

.adblockerTextBottom {
    font-size: var(--ark-18-font-size);
}

.adblockerTextSelected {
    color: var(--ark-main-cyan-figma);
}

.adblockerSectionBottom {
    background-color: var(--ark-advantage-blue-figma);
    background-image: url(./illustrations/profile-subscription/lights-left.png),
        url(./illustrations/profile-subscription/top-right-bg2.png);
    background-position: right top, center right;
    background-repeat: no-repeat, no-repeat;
    position: relative;
    height: 40%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid var(--ark-primary-white-figma);
}

.adblockerSectionBottomLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 100%;
    font-size: var(--ark-32-font-size);
    font-weight: var(--ark-font-weight-light);
}

.removeAdsButton {
    padding: var(--ark-spacing-xs) var(--ark-spacing-xl);
}

.adblockerSectionBottomRight {
    width: 250px;

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-28-line-height);
    color: var(--ark-primary-white-figma);
}

.benefitItem {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: var(--ark-16-font-size);
}

.benefitIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: skewX(-10deg);
    min-width: 80px;
    min-height: 60px;
    background: var(--ark-primary-blue-figma);
    border-radius: 20px;
}

.benefitIcon {
    transform: skewX(10deg);
}

.helpPageLink {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    color: var(--ark-primary-blue-figma);
}

/***************************************************************/
/******************** RESPONSIVE PART **************************/
/***************************************************************/

/* 1024 - 1359 */
@media (max-width: var(--ark-medium-desktop-figma-below-1359)) {
    .adblockerTextTop {
        font-size: var(--ark-24-font-size);
        line-height: var(--ark-30-line-height);
    }
    .adblockerTextMiddle {
        font-size: var(--ark-14-font-size);
        line-height: var(--ark-18-line-height);
        padding: var(--ark-spacing-xxs) 0;
    }
    .adblockerTextBottom {
        font-size: var(--ark-14-font-size);
    }
    .adblockerSectionBottom {
        padding: var(--ark-spacing-xxl-2);
        height: 25%;
    }
    .adblockerSectionBottomLeft {
        justify-content: flex-start;
        width: 100%;
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-28-line-height);
    }
    .adblockerAdvantageIcon, .adblockerPlusIcon svg {
        width: 80px !important;
    }
    .removeAdsButton {
        position: absolute;
        right: var(--ark-spacing-xxl-2);
        top: 50%;
        margin-top: -14px;
    }
    .adblockerSectionBottomRight {
        display: none;
    }
}

/* 840-1023 */
@media (max-width: var(--ark-small-desktop-figma-below-1023)) {
    .container {
        margin-top: 0;
        height: 100vh;
    }
    .adblockerHeader {
        height: 70px;
    }
    .adblockerSectionBottom {
        height: 40%;
        padding: var(--ark-spacing-xxl) 0;
    }
    .adblockerAdvantageIcon {
        width: initial;
    }
    .adblockerPlusIcon svg {
        width: 164px !important;
        height: auto !important;
    }
    .adblockerSectionBottomLeft {
        width: auto;
        justify-content: space-evenly;
        line-height: var(--ark-48-line-height);
        font-size: var(--ark-24-font-size);
    }
    .removeAdsButton {
        position: inherit;
        margin-top: 0;
    }
    .adblockerSectionBottomRight {
        display: flex;
        gap: 10px;
    }
}

/* 720-839 */
@media (max-width: 840px) {
    .adblockerAdvantageIcon, .adblockerPlusIcon svg {
        width: 113px !important;
    }
    .adblockerSectionBottomRight {
        width: 215px;
        line-height: var(--ark-20-line-height);
    }
}

@media (max-width: 840px) and (orientation: landscape) {
    .adblockerSectionBottom {
        padding: var(--ark-spacing-xxl-2);
    }
    .adblockerSectionBottomLeft {
        width: 100%;
    }
    .adblockerAdvantageIcon, .adblockerPlusIcon svg {
        width: 80px !important;
    }
    .removeAdsButton {
        position: absolute;
        right: var(--ark-spacing-xxl-2);
        top: 50%;
        margin-top: -14px;
    }
    .adblockerSectionBottomRight {
        display: none;
    }
}

/* 600-720 */
@media (max-width: var(--ark-medium-mobile-figma-below-719)) {
    .adblockerTextTop {
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-28-line-height);
    }
    .adblockerTextBottom {
        line-height: var(--ark-24-line-height);
    }
    .adblockerSectionBottom {
        padding: var(--ark-spacing-l);
        height: 25%;
    }
    .adblockerSectionBottomLeft {
        display: block;
        width: 100%;
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-28-line-height);
    }
    .adblockerAdvantageIcon, .adblockerPlusIcon svg {
        width: 80px !important;
    }
    .removeAdsButton {
        position: absolute;
        right: var(--ark-spacing-l);
        top: 50%;
        margin-top: -14px;
    }
    .adblockerSectionBottomRight {
        display: none;
    }
}

/* 600-720 landscape */
@media (max-width: var(--ark-medium-mobile-figma-below-719)) and (orientation: landscape) {
    .adblockerHeader {
        height: 40px;
    }
    .adblockerHeaderIconContainer {
        width: 50px;
        height: 50px;
    }
    .adblockerSectionTop {
        padding: 0 var(--ark-spacing-xxs) var(--ark-spacing-xxs);
    }
    .adblockerTextTop {
        padding-top: var(--ark-spacing-xxl);
    }
    .adblockerSectionBottom {
        padding: var(--ark-spacing-l);
        height: 40%;
    }
}

/* mobile */
@media (max-width: var(--ark-small-mobile-figma-below-599)) {
    .container {
        background-repeat: no-repeat;
    }

    .adblockerHeader {
        height: 50px;
    }
    .adblockerHeaderIconContainer {
        width: 50px;
        height: 50px;
    }
    .adblockerSectionTop {
        padding: 0 var(--ark-spacing-xxs) var(--ark-spacing-xxs);
    }
    .adblockerTextTop {
        padding-top: var(--ark-spacing-xxl);
    }
    .adblockerSectionBottom {
        justify-content: space-around;
    }
    .adblockerSectionBottomLeft {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }
    .adblockerBottomLeftText {
        width: 100%;
        text-align: center;
    }
    .adblockerAdvantageIcon, .adblockerPlusIcon {
        order: 1;
    }
    .removeAdsButton {
        position: inherit;
        margin-top: 0;
        order: 2;
    }
}
