@import '../../../variables.css';

.areaHeader {
    padding: var(--ark-spacing-xxl, 20px) 35px;
    z-index: 1;
    position: relative
}

.areaHeaderContent {
    display: flex;
    max-width: 1800px;
    margin: 0 auto;
}

.areaHeader.warning {
    padding-bottom: 0;
}

.areaHeader .areaHeaderBanner {
    max-width: 1800px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    font-size: var(--ark-18-font-size);
    font-weight: var(--ark-font-weight-regular);
    color: var(--ark-neutral-white);
    background-color: var(--ark-tertiary-light-color);
    padding: 10px 16px;
}

.areaHeader .areaHeaderBanner a {
    color: var(--ark-yellow-1);
    text-decoration: underline;
}

.areaHeaderRight {
    flex-grow: 1;
}

.pageTitleContainer {
    white-space: nowrap;
    position: relative;
    z-index: 1;
    padding-right: 35px;
}

.pageTitleContainer h1 {
    font-size: var(--ark-32-font-size);
    color: var(--ark-neutral-white);
    font-weight: var(--ark-font-weight-light);
}

.pageGame .pageTitleContainer h1 {
    padding-bottom: 0.7rem;
}

.pageHeaderDescription {
    display: flex;
    align-items: flex-start;
    font-size: var(--ark-24-font-size);
    color: var(--ark-neutral-white);
    line-height: normal;
    position: relative;
    z-index: 1;
}

.areaBreadcrumbsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 1;
    padding-top: var(--ark-spacing-xs, 8px);
}

/* - 1024 */
@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .areaHeaderContent {
        flex-direction: column;
    }

    .pageTitleContainer h1 {
        font-size: var(--ark-22-font-size);
        line-height: 1.45rem;
        
    }

    .pageHeaderDescription {
        font-size: var(--ark-12-font-size);
        padding-top: var(--ark-spacing-xs, 8px);
    }

    .pageHeaderDropdown {
        display: block;
    }

    .pageHeaderDropdown::before {
        content: ' ';
        position: absolute;
        top: 0.8rem;
        right: 1.25rem;
        background-image: url(./icons/lswitcher/arrow.svg);
        width: 34px;
        height: 34px;
    }
}

@media (min-width: var(--ark-large-desktop-1920)) {
    .areaHeader {
        padding: 28px 0;
    }

    .areaHeaderContent, .areaHeader .areaHeaderBanner {
        max-width: 1840px;
    }
}

@media (max-width: var(--ark-medium-mobile-figma)) {
    .pageTitleContainer {
        white-space: normal;
    }
}

/* 320 */
@media (max-width: var(--ark-extra-small-figma)) {
    .pageTitleContainer h1 {
        font-size: var(--ark-20-font-size);
    }

    .pageCategory .pageHeaderDescription {
        font-size: 1.15rem;
    }
}
