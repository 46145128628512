@import '../../../../variables.css';

.gameAreaFooterContent {
    max-width: 1800px;
    margin: 0 auto;
}

.gameAreaFooter {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    grid-template-rows: 360px minmax(0, 1fr);
    padding-bottom: 5.5rem;
}

.gameAreaGameTextContainer {
    padding-left: var(--xxl-new-space);
    padding-right: 5.625rem;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

.gameAreaGameTitle {
    font-size: var(--ark-26-font-size);
    line-height: var(--ark-32-line-height);
    color: var(--ark-neutral-black);
    padding-left: var(--ark-spacing-m);
    padding-right: var(--ark-spacing-m);
    display: flex;
    justify-content: center;
}

.gameAreaRecommendedGamesContainer {
    padding-left: var(--xxl-new-space);
    padding-top: 2.625rem;
    padding-right: 5.625rem;
    overflow: hidden;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

/* 1025 - 1199px */
@media (min-width: var(--ark-small-desktop-1025)) and (max-width: var(--ark-medium-desktop-below-1199)) {
    .gameAreaFooter {
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: auto auto auto;
    }

    .gameAreaGameTextContainer {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        padding-right: var(--xxl-new-space);
    }

    .gameAreaRecommendedGamesContainer {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        padding-right: 0;
    }
}

/* 1200 - infinity */
@media (min-width: var(--ark-medium-desktop-1200)) {
    .gameAreaGameTextContainer {
        padding-top: 2.625rem;
    }
}

/* 0 - 767px */
@media (max-width: var(--ark-large-mobile-below-767)) {
    .gameAreaGameTitle {
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-22-line-height);
        padding-top: var(--ark-spacing-xxl-2);
    }

    .gameAreaRecommendedGamesContainer {
        padding-top: 0;
    }
}

/* DEVICE LANDSCAPE QUERIES */
/* width: 1024 and less
   height: 1024 and less
   orientation landscape */
@media (max-width: var(--ark-small-desktop-figma-1024)) and (max-height: var(--ark-small-desktop-figma-1024)) and (orientation: landscape) {
    .gameAreaGameTitle {
        padding-top: var(--ark-spacing-xxl-2);
    }
}

/* DEVICE GENERAL QUERIES */
/* 0 - 1024px */
@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .gameAreaFooter {
        display: flex;
        flex-direction: column;
        padding-bottom: 4.5rem;
    }

    .gameAreaGameTextContainer {
        padding-left: var(--ark-spacing-m);
        padding-top: var(--ark-spacing-xxl);
        padding-right: var(--ark-spacing-m);
    }

    .gameAreaRecommendedGamesContainer {
        padding-left: var(--ark-spacing-m);
        padding-right: 0;
        padding-top: 0;
    }
}